<template>
  <v-dialog
    :value="$store.state.user.status.showConfirmPassword"
    max-width="450"
    no-click-animation
    persistent
  >
    <v-card>
      <v-card-title class="mb-3">
        <v-icon left>mdi-lock</v-icon> <span class="font-weight-bold">Please Confirm</span>

        <v-btn
          @click="cancel()"
          absolute
          right
          icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="status.confirmError" transition="fade" type="error" border="left">{{ status.confirmError }}</v-alert>

        <v-form ref="passwordForm" @submit.prevent="validateForm()">
          <v-text-field
            v-model="currentPassword"
            label="Current Password"
            :rules="[rules.required]"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            outlined
          ></v-text-field>
        </v-form>

        <div class="d-flex gap-10">
          <v-btn
            @click="validateForm()"
            :loading="status.reauthenticating"
            color="accent"
            class="gradient"
          >Submit</v-btn>

          <v-btn
            @click="cancel()"
            :disable="status.reauthenticating"
            depressed
          >Cancel</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import rules from '@/rules'

export default {
  data() {
    return {
      rules,
      currentPassword: null,
      showPassword: false,
    }
  },

  computed: {
    ...mapState({
      status: state => state.user.status
    })
  },

  methods: {
    ...mapActions('user', [
      'reauthenticate'
    ]),

    cancel() {
      this.$store.commit('user/setShowConfirmPassword', false)
    },

    validateForm() {
      if (this.$refs.passwordForm.validate()) {
        Promise.all([this.reauthenticate(this.currentPassword)])
        .then(() => {
          this.$refs.passwordForm.reset()
        })
      }
    }
  }
}

</script>
